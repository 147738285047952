import axios from "axios";
import authHeader from "./auth-header";

console.log(`connecting to api : ${process.env.REACT_APP_BACKEND_API}`)
// Create axios client, pre-configured with baseURL
let API = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
    withCredentials:false,
    // timeout: 100,
});
  
API.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 403) {
        //window.location.href = '/auth/login';
      }
      return Promise.reject(error);
    }
);

API.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      console.log(error)
      if (error.code === 'ERR_NETWORK') {
        //window.location.href = '/auth/login';
      }
      if (error.code === 'ECONNABORTED') {
        console.log('Request timed out');
      }
      return Promise.reject({
        "response" : {
            "status" : 500,
            "message" : error.code,
            "error" : error,
            "data" : {
                "message" : "unable to process your request for this moment."
            }
        }
      });
    }
  );
  

// Add a request interceptor to add the token to header request
API.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    config.headers["Content-Type"] =  "application/json";
    return config;
});

// Add x-api-key to header
API.interceptors.request.use(function (config) {
  config.headers["x-api-key"] =  process.env.REACT_APP_BACKEND_API_X_API_KEY;
  return config;
});



// Create the request params
const BuildQueryParams = (filter) => {
  const params = filter !== undefined ? 
  Object.keys(filter).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(filter[key])}`).join('&')
  : ""
  return params
}

// Create the url
const BuildURL = (ipURL,params) => {
 
  const url = new URL( ipURL, process.env.REACT_APP_BACKEND_API);
  url.search = params;

  return url.toString()
}



// get investment list
const fetchInvestmentList = (params) => {
    const queryParams = BuildQueryParams(params)

    const url = new URL("investments");
    url.search = queryParams;

    return API.get(url.toString(),{
        headers: authHeader()
    });
};

// userLogin
const userLogin = ({username, password}) => {
    return API.post("login",{
        "username" : username,
        "password":password,
    })
}



const apiService = {
    API,
    fetchInvestmentList,


    userLogin,
    BuildQueryParams,
    BuildURL,
};

export default apiService;
