import { ApolloClient, ApolloLink, InMemoryCache, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

// Error Link to capture errors
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions.code === 'INTERNAL_SERVER_ERROR') {
        console.error('GraphQL 500 Error:', {
          message,
          locations,
          path,
          extensions,
        });
      }
    });
  }

  if (networkError) {
    console.error('Network Error:', {
      message: networkError.message,
      stack: networkError.stack,
    });
  }
});


const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_BACKEND,
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "x-api-key": process.env.REACT_APP_GRAPHQL_BACKEND_X_API_KEY,
    }
  }
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache()
});

export default client;
